<template>
  <main class="h-100">
    <div class="container">
      <h1>uniqueProductID Search</h1>
    </div>
    <div class="container">
      <div class="row justify-content-center" style="margin-bottom: 300px">
        <div class="col-12 col-md-8 mb-3">
          <div class="shadow h-100 p-3 bg-trick-2 rounded-3">
            <h3 class="mt-2">uniqueProductID Search</h3>
            <div class="mb-3">
              <input v-model="addressVariable" class="form-control mt-3"  placeholder="Insert the uniqueProductID" />
              <p class="text-center"><button @click="searchAddress" class="btn btn-lg btn-trick-2 text-white mt-3">Search</button></p>
              <p v-if="addressVariableErrorMessage" class="alert alert-warning mt-3">{{ addressVariableErrorMessage }}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </main>
</template>

<script>
export default {
  name: 'HomePage',
  props: {
    msg: String
  },
    data() {
    return {
      txHash: '',
      addressVariable: '',
      txHashErrorMessage: '',
      addressVariableErrorMessage: ''
    };
  },
  methods: {
    searchTransaction() {
      if (this.txHash.length !== 66) {
        this.txHashErrorMessage = 'Please enter a valid transaction hash.';
      } else {
        this.txHashErrorMessage = ''; // resetta il messaggio di errore
        this.$router.push(`/tx/${this.txHash}`);
      }
    },
    searchAddress() {
      if (this.addressVariable.length !== 42) {
        this.addressVariableErrorMessage = 'Please enter a valid address.';
      } else {
        this.addressVariableErrorMessage = ''; // resetta il messaggio di errore
        this.$router.push(`/address/${this.addressVariable}`);
      }
    }
    ,
    searchContract() {
      if (this.contractVariable.length !== 42) {
        this.contractVariableErrorMessage = 'Please enter a valid contract address.';
      } else {
        this.contractVariableErrorMessage = ''; // resetta il messaggio di errore
        this.$router.push(`/contract/${this.contractVariable}`);
      }
    }
  }
}
</script>

