<template>
  <main class="h-100">
    <div class="container">
      <div v-if="transactionData">
        <h1>Transaction Details</h1>
        <h4 class="mb-4 text-break">Transaction Hash: {{ transactionData.result.hash }}</h4>
        <div v-if="transactionData.result" class="shadow h-100 p-3 bg-trick-1 rounded-3">
          <div class="table-responsive">
            <table class="table">
                <tbody>
                    <tr>
                        <td class="first-col text-nowrap"><strong>Block Number:</strong></td>
                        <td>
                            <a :href="'https://explorer.quadrans.io/blocks/' + transactionData.result.blockNumber" target="_blank">{{ transactionData.result.blockNumber }}</a>
                            &nbsp;
                            <i class="fa-solid fa-arrow-up-right-from-square" title="External link to Quadrans Blockchain Explorer"></i>
                        </td>
                    </tr>
                    <tr>
                        <td><strong>Date:</strong></td>
                        <td>{{ convertTimestampToDate(transactionData.result.timeStamp) }}</td>
                    </tr>
                    <tr>
                        <td><strong>From:</strong></td>
                        <td><router-link :to="'/address/' + transactionData.result.from">{{ transactionData.result.from }}</router-link></td>
                    </tr>
                    <tr>
                        <td><strong>To:</strong></td>
                        <td><router-link :to="'/address/' + transactionData.result.to">{{ transactionData.result.to }}</router-link></td>
                    </tr>
                    <tr>
                        <td><strong>Input:</strong></td>
                        <td class="text-break font-monospace bg-light">{{ transactionData.result.input }}</td>
                    </tr>
                </tbody>
            </table>
          </div>

          <div v-if="transactionData.result.logs && transactionData.result.logs.length">
            <h3>Logs</h3>
              <div v-for="log in transactionData.result.logs" :key="log.index">
                <div class="table-responsive">
                  <table class="table">
                      <tbody>
                          <tr>
                              <td class="first-col"><strong>Address:</strong></td>
                              <td>{{ log.address }}</td>
                          </tr>

                          <tr>
                              <td><strong>Data:</strong></td>
                              <td class="text-break font-monospace bg-light">{{ log.data }}</td>
                          </tr>
                      </tbody>
                  </table>
                </div>
              </div>
          </div>
        </div>
        <p class="text-center mt-4"><a :href="'https://explorer.quadrans.io/tx/' + transactionData.result.hash" target="_blank"><button class="btn btn-lg btn-trick-2 text-white">Verify on Quadrans Blockchain <i class="fa-solid fa-arrow-up-right-from-square"></i></button></a></p>
      </div>
      <div v-else>
        <p>Loading please wait...</p>
      </div>
    </div>
  </main>
</template>

<script>
import axios from 'axios'

export default {
  props: ['txHash'],
  data() {
    return {
    transactionData: null 
    }
  },
  mounted() {
    axios.get(`https://explorer.testnet.quadrans.io/api?module=transaction&action=gettxinfo&txhash=${this.txHash}`)
      .then(response => {
        this.transactionData = response.data
      })
      .catch(error => {
        console.error("Error loading data:", error)
      })
  },
  methods: {
  convertTimestampToDate(timestamp) {
    const date = new Date(timestamp * 1000);
    return date.toLocaleDateString() + ' ' + date.toLocaleTimeString();
  }
}
}
</script>
