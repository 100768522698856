<template>
  <main class="h-100">
    <div class="container">
      <h1>TRICK Project Accounts List</h1>
      <div class="row justify-content-center">
        <div class="col-12 col-md-8 mt-4" style="margin-bottom: 400px;">
          <h5>Each account corresponds to a company participating in the TRICK Project that has published data within the public blockchain Quadrans</h5>
          <ul class="h4 mt-4">
            <li><a href="/address/0xd71857ed16ce23e0b5f0c31bdf8d5be4fea76f15">0xd71857ed16ce23e0b5f0c31bdf8d5be4fea76f15</a></li>
            <li><a href="/contract/0x20384e10d2733e30fe9296ed52da1d6fe44c949b">0x20384e10d2733e30fe9296ed52da1d6fe44c949b</a></li>
            <li><a href="/contract/0x20384e10d2733e30fe9296ed52da1d6fe44c949b">0xd9b6498995BdF747D85DB5295B4404D5Af1FB0fD</a></li>
            <li>...</li>
          </ul>
        </div>
      </div>
    </div>
  </main>
</template>

<script>
export default {
  name: 'HomePage',
  props: {
    msg: String
  },
    data() {
    return {
      txHash: '',
      addressVariable: '',
      txHashErrorMessage: '',
      addressVariableErrorMessage: ''
    };
  },
  methods: {
    searchTransaction() {
      if (this.txHash.length !== 66) {
        this.txHashErrorMessage = 'Please enter a valid transaction hash.';
      } else {
        this.txHashErrorMessage = ''; // resetta il messaggio di errore
        this.$router.push(`/tx/${this.txHash}`);
      }
    },
    searchAddress() {
      if (this.addressVariable.length !== 42) {
        this.addressVariableErrorMessage = 'Please enter a valid address.';
      } else {
        this.addressVariableErrorMessage = ''; // resetta il messaggio di errore
        this.$router.push(`/address/${this.addressVariable}`);
      }
    }
    ,
    searchContract() {
      if (this.contractVariable.length !== 42) {
        this.contractVariableErrorMessage = 'Please enter a valid contract address.';
      } else {
        this.contractVariableErrorMessage = ''; // resetta il messaggio di errore
        this.$router.push(`/contract/${this.contractVariable}`);
      }
    }
  }
}
</script>

