<template>
  <main class="h-100">
    <div class="container">
      <div v-if="transactions">
        <h1>Account Details</h1>
        <h4 class="mb-4 text-break">Address: {{ addressVariable }}</h4>
        <div class="shadow h-100 p-3 bg-trick-2 rounded-3">
            <div>
                <div class="table-responsive">
                    <table class="table">
                        <thead>
                            <tr>
                                <th>Transactions Hash</th>
                                <th>Date</th>
                                <th class="text-nowrap">Block Number</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="transaction in transactions.result" :key="transaction.hash" class="mt-2">
                                <td><router-link :to="'/tx/' + transaction.hash">{{ transaction.hash }}</router-link></td>
                                <td>{{ convertTimestampToDate(transaction.timeStamp) }}</td>
                                <td class="text-nowrap">
                                    <a :href="'https://explorer.quadrans.io/blocks/' + transaction.blockNumber" target="_blank">{{ transaction.blockNumber }}</a>
                                    &nbsp;
                                    <i class="fa-solid fa-arrow-up-right-from-square" title="External link to Quadrans Blockchain Explorer"></i>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
  
        <!-- Controlli di paginazione -->
        <p class="text-center">
            <button @click="loadData(page - 1)" :disabled="page <= 1" class="btn btn-lg btn-trick-1 text-white m-3"><i class="fa-solid fa-angle-left"></i> Previous</button>
            <button @click="loadData(page + 1)" class="btn btn-lg btn-trick-2 text-white m-3">Next <i class="fa-solid fa-angle-right"></i></button>
        </p>
      </div>
      <div v-else>
        Loading please wait...
      </div>
    </div>
  </main>
</template>
  
  <script>
  import axios from 'axios';
  
  export default {
    props: ['addressVariable'],
    data() {
      return {
        transactions: null,
        page: 1,
        offset: 15
      };
    },
    methods: {
      loadData(pageNumber) {
        const address = this.addressVariable;
        
        axios.get(`https://explorer.testnet.quadrans.io/api?module=account&action=txlist&address=${address}&page=${pageNumber}&offset=${this.offset}`)
          .then(response => {
            let newHighlightedTransactions = [];
            
           if (pageNumber === 1 && this.lastPage <= pageNumber && this.transactions && this.transactions.result) {
              newHighlightedTransactions = response.data.result.filter(
                newTx => !this.transactions.result.some(oldTx => oldTx.hash === newTx.hash)
              ).map(tx => tx.hash);
            }
            
            this.transactions = response.data;
            this.page = pageNumber;
            this.lastUpdated = new Date();
            
            if (newHighlightedTransactions.length > 0) {
              this.highlightedTransactions = newHighlightedTransactions;
              
              setTimeout(() => {
                this.highlightedTransactions = [];
              }, 5000);
            }
            
            this.lastPage = pageNumber;
          })
          .catch(error => {
            console.error("Error loading data:", error);
          });
      },
      convertTimestampToDate(timestamp) {
        const date = new Date(timestamp * 1000);
        return date.toLocaleDateString() + ' ' + date.toLocaleTimeString();
      }
    },
    mounted() {
      this.loadData(this.page);
      this.intervalId = setInterval(() => {
        this.loadData(this.page);
      }, 30000);
    },
    beforeDestroy() {
      clearInterval(this.intervalId);
    }
  }
  </script>
  