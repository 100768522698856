import Vue from 'vue'
import Router from 'vue-router'
import HomePage from './components/HomePage.vue'
import AccountsList from './components/AccountsList.vue'
import UniquePID from './components/UniquePIDView.vue'
import TransactionView from './components/TransactionView.vue'
import AccountView from './components/AccountView.vue'
import ContractView from './components/ContractView.vue'

Vue.use(Router)

export default new Router({
  mode: 'history',
  routes: [
    {
      path: "/",
      component: HomePage,
      name: "Home",
    },
    {
      path: "/accounts",
      component: AccountsList,
      name: "AccountsList",
    },
    {
      path: "/pidsearch",
      component: UniquePID,
      name: "UniquePID",
    },
    {
      path: '/tx/:txHash',
      component: TransactionView,
      props: true
    },
    {
      path: '/address/:addressVariable',
      component: AccountView,
      props: true
    },
    {
      path: '/contract/:contractVariable',
      component: ContractView,
      props: true
    }
  ]
})
