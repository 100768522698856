<template>
  <main class="h-100">
    <div class="container">
      <h1>TRICK Explorer</h1>
      <h4 class="mb-2 text-break">TRICK Project Blockchain Explorer is designed to function as a frontend interface for identifying all public transactions executed by the TRICK Project stakeholders</h4>
    </div>
    <div class="container">
      <div class="row justify-content-center">
        <div class="col-12 col-md-8 mb-3">
          <div class="shadow h-100 p-3 bg-trick-2 rounded-3">
            <h3 class="mt-2">Account Search</h3>
            <div class="mb-3">
              <input v-model="addressVariable" class="form-control mt-3"  placeholder="Insert the Account Address" />
              <p class="text-center"><button @click="searchAddress" class="btn btn-lg btn-trick-2 text-white mt-3">Search</button></p>
              <p v-if="addressVariableErrorMessage" class="alert alert-warning mt-3">{{ addressVariableErrorMessage }}</p>
            </div>
          </div>
        </div>
      </div>
      <div class="row justify-content-center mt-4">
        <div class="col-12 col-md-8 mb-3">
          <div class="shadow h-100 p-3 bg-trick-1 rounded-3">
            <h3 class="mt-2">Transaction Search</h3>
            <div class="mb-3">
              <input v-model="txHash" class="form-control mt-3"  placeholder="Insert the Transaction Hash" />
              <p class="text-center"><button @click="searchTransaction" class="btn btn-lg btn-trick-1 text-white mt-3">Search</button></p>
              <p v-if="txHashErrorMessage" class="alert alert-warning mt-3">{{ txHashErrorMessage }}</p>
            </div>
          </div>
        </div>
      </div>
      <div class="row justify-content-center mt-4">
        <div class="col-12 col-md-8 mb-3">
          <div class="shadow h-100 p-3 bg-trick-2 rounded-3">
            <h3 class="mt-2">Contract Search</h3>
            <div class="mb-3">
              <input v-model="contractVariable" class="form-control mt-3"  placeholder="Insert the Contract Address" />
              <p class="text-center"><button @click="searchContract" class="btn btn-lg btn-trick-2 text-white mt-3">Search</button></p>
              <p v-if="contractariableErrorMessage" class="alert alert-warning mt-3">{{ contractVariableErrorMessage }}</p>
            </div>
          </div>
        </div>
      </div>
      <div class="row justify-content-center mt-4">
        <div class="col-12 col-md-8 mb-3">
          <h3>Examples</h3>
          <p class="mt-3 text-break">Address: <a href="/address/0xd71857ed16ce23e0b5f0c31bdf8d5be4fea76f15">0xd71857ed16ce23e0b5f0c31bdf8d5be4fea76f15</a><br>
          Updated every 30 seconds with latest transactions highlighted for 5 seconds each time</p>
          <p class="mt-3 text-break">Contract: <a href="/contract/0x20384e10d2733e30fe9296ed52da1d6fe44c949b">0x20384e10d2733e30fe9296ed52da1d6fe44c949b</a><br>
          Updated every 30 seconds with latest transactions highlighted for 5 seconds each time</p>
          <p class="text-break">Transaction: <a href="/tx/0xf4814e47f7cdc410a0172bc4fda912c49a4373718bb948533f840f0421410c68">0xf4814e47f7cdc410a0172bc4fda912c49a4373718bb948533f840f0421410c68</a><br>
          Transaction details</p>
        </div>
      </div>
    </div>
  </main>
</template>

<script>
export default {
  name: 'HomePage',
  props: {
    msg: String
  },
    data() {
    return {
      txHash: '',
      addressVariable: '',
      txHashErrorMessage: '',
      addressVariableErrorMessage: ''
    };
  },
  methods: {
    searchTransaction() {
      if (this.txHash.length !== 66) {
        this.txHashErrorMessage = 'Please enter a valid transaction hash.';
      } else {
        this.txHashErrorMessage = ''; // resetta il messaggio di errore
        this.$router.push(`/tx/${this.txHash}`);
      }
    },
    searchAddress() {
      if (this.addressVariable.length !== 42) {
        this.addressVariableErrorMessage = 'Please enter a valid address.';
      } else {
        this.addressVariableErrorMessage = ''; // resetta il messaggio di errore
        this.$router.push(`/address/${this.addressVariable}`);
      }
    }
    ,
    searchContract() {
      if (this.contractVariable.length !== 42) {
        this.contractVariableErrorMessage = 'Please enter a valid contract address.';
      } else {
        this.contractVariableErrorMessage = ''; // resetta il messaggio di errore
        this.$router.push(`/contract/${this.contractVariable}`);
      }
    }
  }
}
</script>

